export default {
    CATEGORIES: [
        {
            key: "appetizers",
            label: "Appetizers",
        },
        {
            key: "maincourse",
            label: "Main Course",
        },
        {
            key: "biryani",
            label: "Biryani",
        },
        {
            key: "dosa",
            label: "Dosa",
        },
        {
            key: "naan",
            label: "Naan",
        },
        {
            key: "tandoor",
            label: "Tandoor",
        },
        {
            key: "desserts_and_beverages",
            label: "Desserts & Beverages",
        },
    ],
    PARTY_ORDER_CATEGORIES: [
        {
            key: "CHICKEN_APPETIZER",
            label: "Chicken Appetizer",
        },
        {
            key: "GOAT_APPETIZER",
            label: "Goat Appetizer",
        },
        {
            key: "FISH_APPETIZER",
            label: "Fish Appetizer",
        },
        {
            key: "SHRIMP_APPETISER",
            label: "Shrimp Appetiser",
        },
        {
            key: "GOBI_APPETIZER",
            label: "Gobi Appetizer",
        },
        {
            key: "PANEER_APPETIZER",
            label: "Paneer Appetizer",
        },
        {
            key: "BABY_CORN_APPETIZER",
            label: "Baby Corn Appetizer",
        },
        {
            key: "VEG_APPETIZER",
            label: "Veg Appetizer",
        },
        {
            key: "CUSTOM_APPETIZER",
            label: "Custom Appetizer",
        },
        {
            key: "CHICKEN_ENTRÉE",
            label: "Chicken Entrée",
        },
        {
            key: "GOAT_ENTRÉE",
            label: "Goat Entrée",
        },
        {
            key: "FISH_ENTRÉE",
            label: "Fish Entrée",
        },
        {
            key: "SHRIMP_ENTRÉE",
            label: "Shrimp Entrée",
        },
        {
            key: "VEG_ENTRÉE",
            label: "Veg Entrée",
        },
        {
            key: "PANEER_ENTRÉE",
            label: "Paneer Entrée",
        },
        {
            key: "CUSTOM_VEG_ENTRÉE",
            label: "Custom Veg Entrée",
        },
        {
            key: "CUSTOM_NON_VEG_ENTRÉE",
            label: "Custom Non Veg Entrée",
        },
        {
            key: "TANDOOR",
            label: "Tandoor",
        },
        {
            key: "BREAD",
            label: "Bread",
        },
        {
            key: "BEVERAGES",
            label: "Beverages",
        },
        {
            key: "DESSERTS",
            label: "Desserts",
        },
    ],
    LOCATIONS: [
        // {
        //     key: "Allentown",
        //     label: "Allentown",
        // },
        // {
        //     key: "Norristown",
        //     label: "Norristown",
        // },
        // {
        //     key: "Hillsborough",
        //     label: "Hillsborough",
        // },
        // {
        //     key: "Maryland",
        //     label: "Maryland",
        // },
        // {
        //     key: "BiryaniHub",
        //     label: "Biryani Hub",
        // },
        {
            key: "BiryaniCityVirginia",
            label: "Biryani Hub Virginia",
        },
    ],
    ROLES: [
        {
            key: "LOCATION_MANAGER",
            label: "Location Manager",
        },
    ],
    DELIVERY_MODE: [
        { key: "Pickup", label: "Pickup" },
        { key: "Delivery", label: "Delivery" },
    ],
    ORDER_SEARCH_STATUS: [
        { key: "ALL", label: "All" },
        { key: "OPEN", label: "Open" },
        { key: "COMPLETED", label: "Completed" },
        { key: "CANCELLED", label: "Cancelled" },
    ],
    ORDER_STATUS: [
        { key: "OPEN", label: "Open" },
        { key: "COMPLETED", label: "Completed" },
        { key: "CANCELLED", label: "Cancelled" },
    ],
    ORDER_STATUS_MAP: {
        "ALL": 'All',
        "OPEN": 'Open',
        "COMPLETED": 'Completed',
        "CANCELLED": 'Cancelled'
    },
    SPICE_LEVEL: [
        { key: "AAA Mild", label: "AAA Mild" },
        { key: "AAA Medium", label: "AAA Medium" },
        { key: "AAA Spicy", label: "AAA Spicy" },
        { key: "Mild", label: "Mild" },
        { key: "Medium", label: "Medium" },
        { key: "Spicy", label: "Spicy" },
        { key: "Very Spicy", label: "Very Spicy" },
        { key: "Very Very Spicy", label: "Very Very Spicy" },

    ],
    TRAY_SIZE: [
        { key: "Large Tray", label: "Large Tray" },
        { key: "Medium Tray", label: "Medium Tray" },
        { key: "Small Deep Tray", label: "Small Deep Tray" },
        { key: "Small Shallow Tray", label: "Small Shallow Tray" },
        { key: "Gallons", label: "Gallons" },
    ],
    CATERING_YES_NO: [
        { key: true, label: "Yes" },
        { key: false, label: "No" },
    ],
    CATERING_CONDIMENTS: [
        { key: "Chutney", label: "Chutney" },
        { key: "Pickle", label: "Pickle" },
    ],
    REGEX: {
        email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        number: /^[0-9]+$/,
        onlystring: /^[A-Za-z\s]*$/,
        pan: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
        degrees: /^[0-9-+.]*$/,
        float: /^[0-9.]*$/,
        ifsc: /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/,
    },
    STATUS_COLOR_MAP: {
        'open': 'error',
        'completed': 'success',
        'cancelled': 'warning'
    }
}