import devapis from "./devapis";
import stagingapis from "./stagingapis";
import prodapis from "./prodapis";
const localtodev = "http://localhost:3000,http://localhost:3001,http://localhost:3002,";
const localtostage = "";
const localtoprod = "";

export const config = {
    "dev": {
        "base": `${localtodev}https://bcitydevlogin.impetusaisolutions.com`,
        "api_base": "https://bcitydevapi.impetusaisolutions.com",
        "static_token": "",
        ...devapis
    },
    "staging": {
        "base": `${localtostage}https://bcitydevlogin.impetusaisolutions.com`,
        "api_base": "https://bcitydevapi.impetusaisolutions.com",
        "static_token": "",
        ...stagingapis
    },
    "production": {
        "base": `${localtoprod}https://login.biryanicitymd.com`,
        "api_base": "https://api.biryanicitymd.com",
        "static_token": "",
        ...prodapis
    }
}

export default config;